export default {
    m: {
        text1: "连接",
        text2: "星际空间",
        text3: "2100年,地球因环境和气候变化变得难以生存,您需要招募更多的宇航员来操作宇宙飞船。每完成一次招募,飞船的等级将得到一次巨大提升,宇航们的相互配合可以使飞船获得更多的能量从而在广阔的空间中完成星际移民。我们将本次行动代号称之为-星链行动。",
        text4: "Pancakeswap",
        text5: "星链行动",
        text6: "审计报告",
        text7: "白皮书",
        text8: "项目简介",
        text9: "星际空间是一款GameFi游戏,第一阶段将致力于基础生态发展,和更多的代币挖掘。当代币挖掘到一定程度,市场具备足够的流动性时,我们将共同正式进入全新的阶段。",
        text10: "代币经济学",
        text11: "配额",
        text12: "BSC上的Token合约地址",
        text13: "前往农场",
        text14: "产品规划",
        text15: "待办事项列表和里程碑，可随时更改。",
        text16: "生态系统  合作伙伴",
        text17: "关注我们",
        text18: "招募新的宇航员,从而升级您的飞船将您推送到更高级的星际空间。",
        text19: "星际农场",
        text20: "将LP(SDR-USDT)质押在农场中,从而获取更多的耕种奖励。",
        text21: "荣誉殿堂",
        text22: "Stars 根据当前空间位置,领取对应等级的NFT(荣誉勋章)。",
        text23: "GameFi",
        text24: "Stars通过游戏,获得更多资源，赚取更多的Token(GSDR)。",
        text25: "项目名称：",
        text26: "发行量：",
        text27: "区块链网络",
        text28: "预售",
        text29: "星链晋级奖励",
        text30: "实验室",
        text31: "第一阶段",
        text32: "Token预售 & DEX",
        text33: "流动性锁定",
        text34: "合约审计报告",
        text35: "第二阶段",
        text36: "启动星链",
        text37: "开放Token购买",
        text38: "启动农场",
        text39: "第三阶段",
        text40: "CMC & CG & CEX",
        text41: "Web3.0",
        text42: "第四阶段",
        text43: "全新赛道",
        text44: "Stars of Level",
        text45: "名额",
        text46: "第二空间奖励",
        text47: "第三空间奖励",
        text48: "飞船等级",
        text49: "当前空间",
        text50: "未连接钱包",
        text51: "断开",
        text52: "启 动",
        text53: "已启动",
        text54: "我的仓库",
        text55: "升级能量",
        text56: "空间能量",
        text57: "添加燃料",
        text58: "已提金额",
        text59: "可提金额",
        text60: "提取金额",
        text61: "招募值",
        text62: "邀请码",
        text63: "招募新人",
        text64: "需要邀请代码",
        text65: "输入邀请码",
        text66: "开启中",
        text67: "成功",
        text68: "失败",
        text69: "请先启动",
        text70: "暂无提取金额",
        text71: "质押您的SDR-USDT,赚取SDR奖励",
        text72: "质押中的SDR-USDT",
        text73: "Authorize",
        text74: "赎回",
        text75: "赚取的SDR",
        text76: "提取",
        text77: "如何下注？",
        text78: "如何下注",
        text79: "常见问题回答",
        text80: "  在这里，你会发现下面最常见的问题及其答案。如果你找不到你要找的东西，请随时在我们的官方discord或telegram群中询问。",
        text81: "Pool 介绍",
        text82: "分配：5000 SDR COINS",
        text83: "运行时间：1000天（约）",
        text84: "开始时间：2023年1月1日上午12:00（UTC）",
        text85: "每个区块奖励：5枚 SDR COINS",
        text86: "如何对Pool下注？",
        text87: "将您的SDR 与 USDT 通过Pancakeswap 添加流动性，然后打开农场并存入您的LP(SDR-USDT)。您可以在存款后实时查看您的奖励。",
        text88: "如何获取Staked DEX（sDEX）？",
        text89: "步骤1：连接钱包。",
        text90: "步骤2：写入金额。",
        text91: "步骤3：单击“将PINK转换为sPINK”以获取sPINK。如果您想取回PINK，请“用sPINK兑换PINK”。",
        text92: "步骤4：确认tx。",
        text93: "收益计算器",
        text94: "Staking Pool统计和收益计算器",
        text95: "Pool 统计信息",
        text96: "预计收益",
        text97: "抵押金额：",
        text98: " LP",
        text99: "计算收益",
        text100: "总质押",
        text101: "Pool 余额",
        text102: "SDR",
        text103: "最终区块",
        text104: "区块",
        text105: "剩余区块",
        text106: "预计APR",
        text107: "小时",
        text108: "天",
        text109: "星期",
        text110: "月",
        text111: "年",
        text112: "语言",
        text113: "暂未开放",
        text114: "复制成功",
        text115: "质押",
        text116: "余额不足",
        text117: "请输入质押数量",
        text118: "取消",
        text119: "确定",
        text200: "可用余额",
        text201: "提取质押",
        text202: "提取后耕种奖励清零",
        text203: "当前可提",
        text204: "请输入提取数量",
        text205: "LP质押总数",
        text206: "请输入整数",
        text207: "已结束",
        text208: "分",
        text209: "秒",
        text210: "距离开始：",
        text211: "切换视角",
        text212: "领取质押中的LP",
        text213: "直推列表",
        text214: "暂无直推",
        text215: "空",
        text216: "直",
        text217: "见",
        text218: "耕种",
        text219: "收获",
        text220: "可收取收益",
        text221: "耕种数量",
        text222: "剩余奖励",
        text223: "耕种收益",
        text224: "耕种USDT,赚取SDR",
        text225: "请输入正确的耕种数量，大于等于100，小于等于10000",
        text226: "请输入邀请人地址",
        text227: "授权失败",
        text228: "耕种成功",
        text229: "暂无收益提取",
        text230: "提取成功",
        text231: "提取失败",
        text232: "招募值",
        text233: "招募奖励",
        text234: "领取",
        text235: "领取成功",
        text236: "领取失败",
        /*老翻译*/
    }
}