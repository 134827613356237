import store from "@/store";
import contract from "./contract.js";
import { bignumber } from "mathjs";
const Web3 = require('web3');
export default {
    install(Vue) {
        /*封装公告方法*/

        // 截取钱包地址
		// 描述: 对钱包地址进行截取
		// 参数: addres@String：需要截取的钱包地址
		// 返回值：@String 截取后的地址
		(Vue.prototype.substrAddres = (addres)=>{
			if(!addres){
				return;
			}
			var head = addres.substr(0,6);
			var leng = addres.substr(addres.length-4,4);
			return head + "..." + leng;
		});

        // 获取钱包地址
        (Vue.prototype.getAddres = () => {
            return store.state.addres;
        });

        // 授权拿取钱包
        (Vue.prototype.authorization = () => {
            return new Promise((resolve,reject)=>{
                if (window.ethereum){
                    window.ethereum.request({
                        method: 'eth_requestAccounts'
                    }).then((res)=>{
                        store.state.addres = res[0];
                        localStorage.setItem("address",res[0]);
                        resolve();
                    });
               } else {
                    reject();
                    window.alert("Please Connection Bsc Wallet");
               }
            });
        });

        // 创建合约对象
        (Vue.prototype.getContract = (type) => {
            if (window.ethereum){
				var ethObj = new Web3(window.ethereum);
                // 实例化合约
                var contractObj = new ethObj.eth.Contract(contract[type].abi,contract[type].addres);
                return contractObj;
		   } else {
                window.alert("Please Connection Bsc Wallet");
		   }
        });

        // 统一处理精度问题 乘
        (Vue.prototype.accuracyMul = (num)=>{

            return Web3.utils.toWei(num);
        });

         // 统一处理精度问题 除
         (Vue.prototype.accuracyDiv = (num)=>{
            return Web3.utils.fromWei(num);
        });

        // 处理小数点
		(Vue.prototype.decimalAction = (num,len = 2)=>{
			var strNum = num + "";
			if(strNum.indexOf(".") != -1) {
				var array = strNum.split(".");
				if(array[1].length > 2){
				   var head = array[0];
				   var len = array[1].substr(0,len);
				   return head + "." + len;
				}else{
					return strNum;
				}
			}else{
			   return strNum;
			}
		});
    }
}