import publicHead from "@/components/headBack/head.vue"
export default{
    data() {
        return {
            
        }
    },
    components: {
        publicHead
    }
}