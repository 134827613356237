<template>
    <div class="startTemBox">
        <!--钱包地址-->
        <div class="addres alignCenter">
            <span>{{substrAddres(addres)}}</span>
        </div>
        <!--结构-->
        <div class="structure alignCenter flexWrap">
            <div class="label alignCenter">
                <div class="value alignCenter"><span class="color1"></span></div>
            </div>
            <div class="label alignCenter">
                <div class="value alignCenter"><span :class="judeColorAndText(0).color" @click.stop="lookChildAddres(0)">{{judeColorAndText(0).name}}</span></div>
                <div class="value alignCenter"><span :class="judeColorAndText(1).color" @click.stop="lookChildAddres(1)">{{judeColorAndText(1).name}}</span></div>
            </div>
            <div class="label alignCenter">
                <div class="value alignCenter"><span :class="judeColorAndText(2).color" @click.stop="lookChildAddres(2)">{{judeColorAndText(2).name}}</span></div>
                <div class="value alignCenter"><span :class="judeColorAndText(4).color" @click.stop="lookChildAddres(4)">{{judeColorAndText(4).name}}</span></div>
                <div class="value alignCenter"><span :class="judeColorAndText(3).color" @click.stop="lookChildAddres(3)">{{judeColorAndText(3).name}}</span></div>
                <div class="value alignCenter"><span :class="judeColorAndText(5).color" @click.stop="lookChildAddres(5)">{{judeColorAndText(5).name}}</span></div>
            </div>
        </div>
        <div class="title">{{$t('m.text213')}}</div>
        <div class="list">
            <div class="inBox">
                <div class="item alignBetween" v-for="(value,index) in pushListArr" :key="index" @click.stop="getViewData(value)">
                    <span>{{substrAddres(value.addres,4,4)}}</span>
                    <span>{{$t("m.text62")}}：{{value.invite}}</span>
                </div>
           
                <div class="not" v-if="pushListArr.length == 0">{{$t("m.text214")}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import contractText from "@/assets/script/contract";
export default {
    data() {
        return {
            // 地址
            addres: "",
            // 查询索引
            searchIndex: 0,
            // 直推索引
            pushNum: 0,
            // 结构数据
            structureArr: [],
            // 直推列表
            pushListArr: []
        }
    },

    async mounted() {
        this.getViewData(this.$store.state.addres);
        await this.getUserPushNumber();
        this.getUserPushValue();
    },

    methods: {
        // 获取数据
        getViewData(address) {
            this.addres = address
            this.structureArr = [];
            var _this = this;
            var contractObj = this.getContract("StarsChain");
            contractObj.methods.getUser(address).call().then((res)=>{
                var backValue = res[3];
                for(var i=0;i<6;i++) {
                    _this.structureArr.push(backValue[i]);
                }
            });
        },

        // 查询直推个数
        getUserPushNumber() {
            var _this = this;
            var contractObj = this.getContract("StarsUser");
            return new Promise((resolve,reject) => {
                contractObj.methods.shareTotal(this.$store.state.addres).call().then((res)=>{
                    _this.pushNum = res;
                    resolve();
                });
            });
        },

        // 查询直推详情
        getUserPushValue() {
            var _this = this;
            var contractObj = this.getContract("StarsUser");
            if(this.searchIndex == this.pushNum) {
                return;
            }
            var addres = "";
            var invite = "";
            contractObj.methods.shares(this.$store.state.addres,this.searchIndex).call().then((res)=>{
                addres = res;
                contractObj.methods.addressToId(res).call().then((response)=>{
                    invite = response;
                    _this.pushListArr.push({addres: addres,invite:invite});
                    _this.searchIndex += 1 ;
                    _this.getUserPushValue();
                });
            });
        },

        //判断颜色和文字
        judeColorAndText(index) {
            if(this.structureArr[index] == "0x0000000000000000000000000000000000000000") {
                return {
                    color: "color4",
                    name: this.$t('m.text215')
                }
            }else{
                let claassName = "";
                let returnName = "";
                if(index == 0 || index == 1) {
                    claassName = "color2";
                    returnName = this.$t('m.text216')
                }else{
                    claassName = "color3";
                    returnName = this.$t('m.text217')
                }
                return {
                    color: claassName,
                    name: returnName
                }
            }
        },

        // 查看指定下级钱包地址
        lookChildAddres(index) {
            let addres = this.structureArr[index];
            if(addres == "0x0000000000000000000000000000000000000000"){
                return;
            }
            this.$createToast({
                type: "txt",
                time: 1500,
                txt: this.substrAddres(addres,6,6)
            }).show();
        }
    }
}
</script>

<style lang="scss">
    @keyframes starsTeamFloat {
        0% {
            transform: translate(0px, 0px);
            width: 60px;
            height: 60px;
        }
        50% {
            transform: translate(0px, 10px);
            width: 55px;
            height: 55px;
        }
        100% {
            transform: translate(0px, 0px);
            width: 60px;
            height: 60px;
        }
    }
    .startTemBox{
        width: 100%;
        height: 100%;
        padding: 40px 10px 0px 10px;
        overflow-y: scroll;
        >.title{
            height: 30px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            padding-left: 40px;
        }
        >.addres{
            >span{
                display: inline-block;
                padding: 0px 15px;
                height: 25px;
                line-height: 25px;
                background-color: rgba(255,255,255,0.2);
                border-radius: 25px;
            }
        }
        >.structure{
            margin-top: 20px;
            >.label{
                width: 100%;
                margin-bottom: 10px;
                >.value{
                    width: 25%;
                    padding: 10px;
                    position: relative;
                    >span{
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        border-radius: 60px;
                    
                    }
                    >.color1{
                        background-color: #f24141;
                    }
                    >.color2{
                        background-color: #57f170;
                        color: #040504;
                    }
                    >.color3{
                        background-color: #e0eb29;
                        color: #040504;
                    }
                    >.color4{
                        background-color: rgba(255,255,255,0.3);
                        color: #FFFFFF;
                    }
                }
            }
        }
        >.list{
            margin-top: 10px;
            height: 200px;
            padding: 0px 40px;
            border-radius: 5px;
            box-sizing: border-box;;
            >.inBox{
                height: 200px;
                overflow-y: scroll;
                border: 1px solid rgba(255,255,255,0.5);
                border-radius: 5px;
                position: relative;
                >.item{
                    height: 40px;
                    line-height: 40px;
                    box-sizing: border-box;
                    padding: 0px 10px;
                    font-size: 14px;
                    border-bottom: 1px solid rgba(255,255,255,0.5);
                }
                >.not{
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    margin-top: -15px;
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }
    }
</style>