export default {
    m: {
        text1: "せつぞく",
        text2: "宇宙空間",
        text3: "2100年、地球は環境や気候変動で生存が難しくなり、宇宙船を操作するためにより多くの宇宙飛行士を募集する必要があります。募集が完了するたびに、宇宙船の等級は大幅に向上し、宇宙飛行士たちの相互協力は宇宙船により多くのエネルギーを与え、広い空間で宇宙移民を完成させることができる。私たちは今回の行動をスターチェーン行動と呼んでいます。",
        text4: "Pancakeswap",
        text5: "スターチェーンアクション",
        text6: "監査レポート",
        text7: "ホワイトペーパー",
        text8: "プロジェクトの概要",
        text9: "星間空間はGameFiゲームで、第1段階は基礎生態の発展とより多くのトークンの発掘に力を入れる。現代貨幣がある程度発掘され、市場が十分な流動性を備えている場合、私たちは共同で正式に新しい段階に入ります。",
        text10: "トークン経済学",
        text11: "クォータ",
        text12: "BSC上のトークン契約アドレス",
        text13: "農場に向かう",
        text14: "製品計画",
        text15: "ToDoリストとマイルストーンは、いつでも変更できます。",
        text16: "エコシステムパートナー",
        text17: "私たちに注目",
        text18: "新しい宇宙飛行士を募集して、宇宙船をアップグレードして、より高度な宇宙空間にお届けします。",
        text19: "農場",
        text20: "LP（SDR-USDT）を農場に質押し、より多くの耕作奨励金を得る。",
        text21: "名誉の殿堂",
        text22: "スターズは現在の空間位置に応じて、対応するレベルのNFT（名誉勲章）を受け取る。",
        text23: "GameFi",
        text24: "スターズはゲームを通じて、より多くのリソースを獲得し、より多くのトークン（GSDR）を獲得する。",
        text25: "プロジェクト名：",
        text26: "発行部数：",
        text27: "ブロックチェーンネットワーク",
        text28: "前売り",
        text29: "スターチェーン昇格奨励金",
        text30: "実験室",
        text31: "第1段階",
        text32: "トークン前売り&DEX",
        text33: "フローロック",
        text34: "契約監査レポート",
        text35: "第2段階",
        text36: "スターチェーンの起動",
        text37: "オープントークン購入",
        text38: "スタートアップファーム",
        text39: "フェーズ3",
        text40: "CMC & CG & CEX",
        text41: "Web3.0",
        text42: "フェーズ4",
        text43: "新しいコース",
        text44: "Stars of Level",
        text45: "定員",
        text46: "第二の空間奨励",
        text47: "第三の空間奨励",
        text48: "ひこうせんレベル",
        text49: "現在のスペース",
        text50: "接続されていません",
        text51: "切断",
        text52: "スタートアップ",
        text53: "開始済み",
        text54: "マイウェアハウス",
        text55: "エネルギーのアップグレード",
        text56: "くうかんエネルギー",
        text57: "燃料を添加する",
        text58: "引出済金額",
        text59: "引出可能金額",
        text60: "金額の抽出",
        text61: "募集値",
        text62: "招待コード",
        text63: "新人募集",
        text64: "招待コードが必要です",
        text65: "招待コードを入力",
        text66: "オープン中",
        text67: "成功",
        text68: "に失敗",
        text69: "まず起動してください",
        text70: "引き出し金額がありません",
        text71: "SDR-USDTを質押し、SDR奨励金を稼ぐ",
        text72: "品質保証中のSDR-USDT",
        text73: "Authorize",
        text74: "請け出す",
        text75: "稼いだSDR",
        text76: "ちゅうしゅつ",
        text77: "どのように注釈をつけますか？",
        text78: "注釈方法",
        text79: "よくある質問への回答",
        text80: " ここでは、次の最も一般的な質問とその答えが見つかります。お探しのものが見つからない場合は、いつでも公式discordまたはtelegramグループでお問い合わせください。",
        text81: "Poolの紹介",
        text82: "割当：5000 SDR COINS",
        text83: "実行時間：1000日（約）",
        text84: "開始時間：2023年1月1日午前12：00（UTC）",
        text85: "ブロックごとの報酬：SDR COINS 5枚",
        text86: "Poolへの注釈方法",
        text87: "SDRとUSDTをPancakeswapで流動性を追加し、農場をオープンしてLP（SDR-USDT）に保存します。入金後にインセンティブをリアルタイムで表示できます。",
        text88: "Staked DEX（sDEX）を取得する方法",
        text89: "手順1：ウォレットを接続します。",
        text90: "手順2：金額を書き込みます。",
        text91: "手順3：「PINKをsPINKに変換」をクリックしてsPINKを取得します。PINKを取り戻したい場合は、「sPINKをPINKに変換」してください。",
        text92: "手順4：txを確認する。",
        text93: "収益計算機",
        text94: "Staking Pool統計と収益計算器",
        text95: "Pool統計",
        text96: "予想収益",
        text97: "担保金額：",
        text98: " LP",
        text99: "収益の計算",
        text100: "総質押",
        text101: "Pool残高",
        text102: "SDR",
        text103: "最終ブロック",
        text104: "ブロック",
        text105: "残りのブロック",
        text106: "推定APR",
        text107: "時間",
        text108: "日",
        text109: "曜日",
        text110: "月",
        text111: "年",
        text112: "言語",
        text113: "まだオープンしていません",
        text114: "コピー成功",
        text115: "抵当に入れる",
        text116: "残高不足",
        text117: "品質保証数量を入力してください",
        text118: "キャンセル",
        text119: "を選択して、",
        text200: "使用可能残高",
        text201: "質押を抽出する.",
        text202: "抽出後の耕作奨励金ゼロ",
        text203: "現在提供可能",
        text204: "抽出数量を入力してください",
        text205: "LP品質保証総数",
        text206: "整数を入力してください",
        text207: "終了",
        text208: "分",
        text209: "秒",
        text210: "距離の開始:",
        text211: "視野角の切り替え",
        text212: "質入れ中のLPを受け取る",
        text213: "直進リスト",
        text214: "当分の間直押しなし",
        text215: "空",
        text216: "直線",
        text217: "見て",
        text218: "耕作する",
        text219: "収穫",
        text220: "受取可能利益",
        text221: "耕作数量",
        text222: "余剰報酬",
        text223: "耕作収益",
        text224: "USDTを耕し、SDRを稼ぐ",
        text225: "耕作数量を入力してください",
        text226: "招待者の住所を入力してください",
        text227: "承認に失敗しました",
        text228: "耕作に成功する",
        text229: "暫定利益抽出なし",
        text230: "抽出に成功しました",
        text231: "抽出に失敗しました",
        text232: "募集値",
        text233: "奨励金を募集する.",
        text234: "受け取る",
        text235: "受け取り成功",
        text236: "受け取りに失敗しました",
        /*老翻译*/
    }
}