import Vue from 'vue'
import VueRouter from 'vue-router'

import home from "@/views/home/home.vue";
import stars from "@/views/stars/stars.vue";
import stake from "@/views/stake/stake.vue";
import question from "@/views/stake/questions.vue";
import calculator from "@/views/calculator/calculator.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/stars',
    name: 'stars',
    component: stars
  },
  {
    path: '/stake',
    name: 'stake',
    component: stake
  },
  {
    path: '/question',
    name: 'question',
    component: question
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: calculator
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
