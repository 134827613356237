<template>
    <div class="headBox">
        <div class="back" @click.stop="backAction"></div>
        <div class="title">{{title}}</div>
        <div class="export">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ""
            }
        },

        data() {
            return {

            }
        },

        methods: {
            backAction() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style lang="scss">
    .headBox{
        width: 100%;
        height: 50px;
        padding: 10px;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10000;
        color: #FFFFFF;
        background: rgba(22, 28, 36, 1);
        >.title{
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
        }
        >.back{
            width: 30px;
            height: 30px;
            background: url(@/assets/image/backIcon.png) no-repeat center center / 20px;
            position: absolute;
            left: 10px;
            top: 10px;
        }
    }
</style>