<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },

  mounted() {
    console.log("启动");
  }
}
</script>


<style lang="scss">
  #app{
    width: 100%;
    height: 100%;
  }
  .viewBox{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
