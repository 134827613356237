<template>
    <div class="menuBox">
        <div class="logoBox">
            <div class="close" @click.stop="closeAction"></div>
        </div>
        <div class="walletConnect" v-if="$store.state.addres">
            <div class="addres">{{substrAddres($store.state.addres)}}</div>
            <div class="but" @click.stop="offWallet">{{$t("m.text51")}}</div>
        </div>
        <div class="walletConnect" v-if="!$store.state.addres">
            <div class="not">{{$t("m.text50")}}</div>
            <div class="but" @click.stop="authorization">{{$t("m.text1")}}</div>
        </div>
        <div class="menuItem" v-for="(value,index) in menuList" :key="index" @click.stop="goToView(value)">
            <div class="icon">
                <img :src="value.icon" />
            </div>
            <div class="name">{{value.name}}</div>
        </div>
        <div class="menuItem" @click.stop="notOpen">
            <div class="icon">
                <img src="@/assets/image/menuIcon7.png" />
            </div>
            <div class="name">{{$t("m.text21")}}</div>
        </div>
        <div class="menuItem" :class="isaboutgSelect? 'export' : ''" @click.stop="fullusExport">
            <div class="icon">
                <img src="@/assets/image/menuIcon8.png" />
            </div>
            <div class="name">{{$t("m.text17")}}</div>
        </div>
        <div class="lang" v-if="isaboutgSelect">
            <div class="item">Telegram Chat</div>
            <div class="item">Telegram Channel</div>
            <div class="item">Discord</div>
            <div class="item">Twitter</div>
        </div>
        <div class="menuItem" :class="isLangSelect? 'export' : ''" @click.stop="expotLang">
            <div class="icon">
                <img src="@/assets/image/menuIcon9.png" />
            </div>
            <div class="name">{{$t("m.text112")}}</div>
        </div>
        <div class="lang" v-if="isLangSelect">
            <div class="item" :class="currLang == 'en' ? 'select' : ''" @click.stop="changeLang('en')">English</div>
            <div class="item" :class="currLang == 'jp' ? 'select' : ''" @click.stop="changeLang('jp')">日本語</div>
            <div class="item" :class="currLang == 'hg' ? 'select' : ''" @click.stop="changeLang('hg')">오래 끄는</div>
            <div class="item" :class="currLang == 'ft' ? 'select' : ''" @click.stop="changeLang('ft')">繁體中文</div>
            <div class="item" :class="currLang == 'cn' ? 'select' : ''" @click.stop="changeLang('cn')">简体中文</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // 是否展开语言选择
                isLangSelect: false,
                // 是否展开关注我们
                isaboutgSelect: false,
                // 当前选择语言
                currLang: "",
                menuList: [
                    {
                        icon: require("@/assets/image/menuIcon1.png"),
                        name: this.$t("m.text28"),
                        link: "https://gempad.app/presale/0xcBd354469BB46372c686F16Da4dF23f7B7095030?chainId=56"
                    },
                    {
                        icon: require("@/assets/image/menuIcon2.png"),
                        name: this.$t("m.text5"),
                        router: "/stars",
                        link: ""
                    },
                    {
                        icon: require("@/assets/image/menuIcon3.png"),
                        name: this.$t("m.text4"),
                        link: "https://pancakeswap.finance/swap?inputCurrency=0x61AE7df82C7bA71014D6570889Efce89b7A1Dc68&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
                    },
                    {
                        icon: require("@/assets/image/menuIcon4.png"),
                        name: this.$t("m.text19"),
                        router: "/stake",
                        link: ""
                    },
                ]
            }
        },

        mounted() {
            this.currLang = localStorage.getItem("language") ? localStorage.getItem("language") : 'en';
        },

        methods: {
            // 关闭菜单
            closeAction() {
                this.$emit("close");
            },

            // 更新菜单
            upDataMenu() {
                this.menuList = [
                {
                        icon: require("@/assets/image/menuIcon1.png"),
                        name: this.$t("m.text28"),
                        link: "https://gempad.app/presale/0xcBd354469BB46372c686F16Da4dF23f7B7095030?chainId=56"
                    },
                    {
                        icon: require("@/assets/image/menuIcon2.png"),
                        name: this.$t("m.text5"),
                        router: "/stars",
                        link: ""
                    },
                    {
                        icon: require("@/assets/image/menuIcon3.png"),
                        name: this.$t("m.text4"),
                        link: "https://pancakeswap.finance/swap?inputCurrency=0x61AE7df82C7bA71014D6570889Efce89b7A1Dc68&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
                    },
                    {
                        icon: require("@/assets/image/menuIcon4.png"),
                        name: this.$t("m.text19"),
                        router: "/stake",
                        link: ""
                    },
                ]
            },

            // 点击切换钱包
            expotLang() {
                this.isLangSelect ? this.isLangSelect = false : this.isLangSelect = true;
            },

            // 点击关注我们
            fullusExport() {
                this.isaboutgSelect ? this.isaboutgSelect = false : this.isaboutgSelect = true;
            },

            // 暂未开放
            notOpen() {
                this.$createToast({
                    time: 1000,
                    type: 'warn',
                    txt: this.$t("m.text113")
                }).show();
            },

            // 前往页面
            goToView(value) {
                if(value.router) {
                    this.$router.push({
                        path: value.router
                    })
                }else if(value.link){
                    window.open(value.link)
                }
            },

            // 断开钱包
            offWallet() {
                this.$store.state.addres = '';
            },

            changeLang(lang) {
                this.$i18n.locale = lang;
                localStorage.setItem("language",lang);
                this.currLang = lang;
                this.upDataMenu();
                this.$emit("changelan");
            }
        }
    }
</script>

<style lang="scss">
    .menuBox{
        width: 100%;
        min-height: 100%;
        padding: 15px;
         background-color: rgba(33, 43, 54, 1);
        >.logoBox{
            position: relative;
            height: 30px;
            background: url(@/assets/image/menuLogo.png) no-repeat left center / auto 25px;
            >.close{
                width: 30px;
                height: 30px;
                background: url(@/assets/image/close1.png) no-repeat center center / 25px;
                position: absolute;
                right: 10px;
                top: 0px;
            }
        }
        >.walletConnect{
            margin-top: 30px;
            padding-left: 45px;
            height: 40px;
            line-height: 40px;
            border-radius: 40px;
            background: rgba(50, 59, 69, 1) url(@/assets/image/bscIcon.png) no-repeat left 10px center / 25px;
            position: relative;
            >.addres{
                height: 40px;
                line-height: 40px;
                font-size: 14px;
            }
            >.not{
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.3500);
            }
            >.but{
                position: absolute;
                top: 0px;
                right: 15px;
                color: rgba(0, 226, 232, 1);
            }
        }
        >.menuItem{
            position: relative;
            margin-top: 20px;
            background: url(@/assets/image/inIcon1.png) no-repeat right center / 20px;
            >.icon{
                width: 26px;
                height: 26px;
                position: absolute;
                left: 0px;
                top: 50%;
                margin-top: -13px;
                >img{
                    width: 26px;
                    height: 26px;
                }
            }
            >.name{
                padding-left: 40px;
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                color: rgba(176, 180, 184, 1);
            }
        }
        >.export{
            background: url(@/assets/image/inIcon2.png) no-repeat right center / 20px!important;
        }
        >.lang{
            margin-top: 10px;
            padding-left: 40px;
            >.item{
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6500);
            }
            >.select{
                color: rgba(0, 226, 232, 1)!important;
            }
        }
    }
</style>