import publicHead from "@/components/headBack/head.vue";
import contractText from "@/assets/script/contract";
import dowwnTime from "@/components/downTime/downtime.vue";
const Web3 = require('web3');
export default{
    data() {
        return {
            // 耕种索引
            cultLen: 0,
            // 当前耕种索引
            currCultLen: 0,
            // 耕种数量
            cultivatNum: 0,
            // 剩余耕种数量
            surplusNum: 0,
            // 可收获数量
            inCommMoney: 0,
            // 耕种数量
            inputCultivat: "",
            // 邀请人地址
            intiveAddres: "",
            // 耕种显示
            showcultivat: false,
            // 倒计时
            intveTime: null,
            // 招募值
            findNum: 0,
            // 招募奖励
            findMone: 0,
        }
    },

    async mounted() {
        await this.authorization();
        await this.getCultivatLength();
        this.getCultivatNumb();
        this.getParseAddres();
        this.getSdrNumber();
        this.getFindNumAction();
        this.getFindMoneyAction();
        this.intveTime = setInterval(()=>{
            this.getSdrNumber();
        },3000);
    },

    beforeDestroy() {
        clearInterval(this.intveTime);
    },

    methods: {
        // 获取耕种Length
        getCultivatLength() {
            let _this = this;
            let loding = this.$createToast({
                txt: "",
                time: 0,
                mask: true,
                type: 'loading',
            }).show();
            return new Promise((resolve,reject)=>{
                let contractObj = this.getContract("FarmingPool");
                contractObj.methods.capitalLength(this.$store.state.addres).call().then((res)=>{
                    _this.cultLen = res;
                    loding.hide();
                    resolve();
                }).catch(()=>{
                    loding.hide();
                })
            });
        },

        // 获取已耕种数量和剩余奖励
        getCultivatNumb() {
            if(this.cultLen == 0) {
                return;
            }
            if(this.currCultLen == this.cultLen) {
                this.currCultLen = 0;
                return;
            }
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            contractObj.methods.capitals(this.$store.state.addres,this.currCultLen).call().then((res)=>{
                _this.cultivatNum += parseFloat(_this.accuracyDiv(res.amount));
                _this.surplusNum += parseFloat(_this.decimalAction(_this.accuracyDiv(res.balance),6));
                _this.currCultLen += 1;
                _this.getCultivatNumb();
            });
        },

        // 获取奖励SDR数量
        getSdrNumber() {
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            contractObj.methods.rewarding(this.$store.state.addres).call().then((res)=>{
                _this.inCommMoney = _this.decimalAction(_this.toFixedNum(Web3.utils.fromWei(res)),10);
                
            });
        },

        // 获取当前用户上级钱包地址
        getParseAddres() {
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            contractObj.methods.inviter(this.$store.state.addres).call().then((res)=>{
                if(res != "0x0000000000000000000000000000000000000000") {
                    _this.intiveAddres = res;
                }
            });
        },

        // 获取招募数值
        getFindNumAction() {
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            contractObj.methods.teamAchieve(this.$store.state.addres).call().then((res)=>{
                _this.findNum = _this.decimalAction(_this.accuracyDiv(res),6);
            })
        },

        // 获取招募奖励
        getFindMoneyAction() {
            let contractObj = this.getContract("LeaderShare");
            let _this = this;
            contractObj.methods.pending(this.$store.state.addres).call().then((res)=>{
                _this.findMone = _this.decimalAction(_this.accuracyDiv(res),6);
            });
        },

        // 领取招募奖励
        getFindMoneyToAddres() {
            let contractObj = this.getContract("LeaderShare");
            let _this = this;
            let loding = this.$createToast({
                txt: "",
                time: 0,
                mask: true,
                type: 'loading',
            }).show();
            let  reward = contractObj.methods.reward();
            reward.send({
                from: this.$store.state.addres
            }).then((res)=>{
                loding.hide();
                _this.$createToast({
                    txt: _this.$t('m.text235'),
                    time: 1500,
                    mask: true,
                    type: 'correct',
                }).show();
                _this.getFindNumAction();
                _this.getFindMoneyAction();
            }).catch(()=>{
                loding.hide();
                _this.$createToast({
                    txt: _this.$t('m.text236'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
            });
        },

        // 确定耕种
        confirmCultivatAction() {
            if(!this.inputCultivat || parseFloat(this.inputCultivat) < 100 || parseFloat(this.inputCultivat)>10000) {
                this.$createToast({
                    txt: this.$t('m.text225'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
                return;
            }
            if(!this.intiveAddres) {
                this.$createToast({
                    txt: this.$t('m.text226'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
                return;
            }
            let contractObj = this.getContract("USDT");
            let _this = this;

            let approve = contractObj.methods.approve(contractText['FarmingPool'].addres,Web3.utils.toWei(this.inputCultivat));
            let loding = this.$createToast({
                txt: "",
                time: 0,
                mask: true,
                type: 'loading',
            }).show();
            approve.send({
                from: this.$store.state.addres
            }).then((res)=>{
                _this.showcultivat = false;
                loding.hide();
                _this.startCultivatAction(_this.inputCultivat,_this.intiveAddres);
                _this.inputCultivat = "";
                _this.intiveAddres = "";
            }).catch(()=>{
                _this.$createToast({
                    txt: _this.$t('m.text227'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
            });
        },

        // 启动耕种
        startCultivatAction(moeny,address) {
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            let start = contractObj.methods.launch(address,this.accuracyMul(moeny));
            let loding = this.$createToast({
                txt: "",
                time: 0,
                mask: true,
                type: 'loading',
            }).show();
            start.send({
                from: this.$store.state.addres
            }).then((res)=>{
                loding.hide();
                _this.$createToast({
                    txt: _this.$t('m.text228'),
                    time: 1500,
                    mask: true,
                    type: 'correct',
                }).show();
                setTimeout(()=>{
                    _this.cultivatNum = 0;
                    _this.surplusNum = 0;
                    _this.getCultivatNumb();
                },3000);
            }).catch(()=>{
                loding.hide();
                _this.$createToast({
                    txt: _this.$t('m.text68'),
                    time: 1500,
                    mask: true,
                    type: 'correct',
                }).show();
            });
        },

        // 提取收益
        getIncommMone() {
            if(!this.inCommMoney) {
                _this.$createToast({
                    txt: _this.$t('m.text229'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
                return;
            }
            let contractObj = this.getContract("FarmingPool");
            let _this = this;
            let loding = this.$createToast({
                txt: "",
                time: 0,
                mask: true,
                type: 'loading',
            }).show();
            let reward = contractObj.methods.reward();
            reward.send({
                from: this.$store.state.addres
            }).then((res)=>{
                loding.hide();
                _this.surplusNum = 0;
                _this.cultivatNum = 0;
                _this.getCultivatNumb();
                _this.$createToast({
                    txt: _this.$t('m.text230'),
                    time: 1500,
                    mask: true,
                    type: 'correct',
                }).show();
            }).catch(()=>{
                loding.hide();
                _this.$createToast({
                    txt: _this.$t('m.text231'),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
            });
        },

        // 前往提问
        goToQuest() {
            this.$router.push({
                path: "/question"
            })
        },

        // 前往农场
        goToComputer() {
            this.$router.push({
                path: "/calculator"
            })
        },

        // 显示耕种
        showCultivationAction() {
            this.showcultivat = true;
        },

        // 关闭耕种
        closeCultivationAction() {
            this.showcultivat = false;
            this.inputCultivat = "";
            this.intiveAddres = "";
        },
        
        // 科学计算方法
        toFixedNum(x) {
            if (Math.abs(x) < 1.0) {
                var e = parseInt(x.toString().split('e-')[1]);
                if (e) {
                    x *= Math.pow(10,e-1);
                    x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
                }
              } else {
                var e = parseInt(x.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    x /= Math.pow(10,e);
                    x += (new Array(e+1)).join('0');
                }
              }
              return x;
        },

    },
    components: {
        publicHead,
        dowwnTime
    }
}