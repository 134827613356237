export default {
    m: {
        text1: "connection",
        text2: "Star Space",
        text3: "In 2100, the earth will become difficult to survive due to environmental and climate changes. You need to recruit more astronauts to operate the spaceship. Each time the recruitment is completed, the level of the spaceship will be greatly improved. The cooperation of the astronauts can enable the spaceship to obtain more energy and complete the interstellar migration in the vast space. We call this operation Star Chain Operation.",
        text4: "Pancakeswap",
        text5: "Star Chain",
        text6: "Audit Report",
        text7: "Whitepaper",
        text8: "Introduction",
        text9: "Interspace is a GameFi. The first stage will focus on basic ecological development and more token mining. When the contemporary currency is excavated to a certain extent and the market has sufficient liquidity, we will formally enter a new stage together.",
        text10: "Economics",
        text11: "Quota",
        text12: "contract on BSC",
        text13: "Star Farming",
        text14: "Planning",
        text15: "To do list and milestones, subject to change.",
        text16: "Ecosystem Partners",
        text17: "About",
        text18: "Recruit new astronauts to upgrade your spaceship and push you to higher interstellar space.",
        text19: "Star Farming",
        text20: "Pledge LP (SDR-USDT) in the farm to obtain more farming rewards.",
        text21: "Honour",
        text22: "Stars will receive the corresponding level NFT (Medal of Honor) according to the current space location.",
        text23: "GameFi",
        text24: "Stars can get more resources and earn more Token (GSDR) through games.",
        text25: "Project Name：",
        text26: "Supply total：",
        text27: "Network",
        text28: "Presale",
        text29: "Promotion  reward",
        text30: "Lab",
        text31: "Phase 1",
        text32: "Token Presale & DEX",
        text33: "Liquidity lock",
        text34: "Audit Report",
        text35: "Phase 2",
        text36: "Startup",
        text37: "Open Token Purchase",
        text38: "Startup",
        text39: "Phase 3",
        text40: "CMC & CG & CEX",
        text41: "Metaverse & Web3.0",
        text42: "Phase 4",
        text43: "New Roadmap",
        text44: "Stars of Level",
        text45: "Quota",
        text46: "Second space reward",
        text47: "Third space reward",
        text48: "Airship Level",
        text49: "Space Level",
        text50: "not connected",
        text51: "disconnect",
        text52: "Startup",
        text53: "Started",
        text54: "My Store",
        text55: "Upgrade energy",
        text56: "Space energy",
        text57: "Refueling",
        text58: "withdrawn",
        text59: "Withdrawable ",
        text60: "Withdraw",
        text61: "Recruitment ",
        text62: "Invitation code",
        text63: "Recruit",
        text64: "Invitation code required",
        text65: "Enter invitation code",
        text66: "Launching",
        text67: "Success",
        text68: "Fail",
        text69: "Please Launch",
        text70: "No withdrawal",
        text71: "Pledge your SDR-USDT to earn SDR rewards",
        text72: "SDR-USDT in pledge",
        text73: "Authorize",
        text74: "Withdraw",
        text75: "Earned",
        text76: "Withdraw",
        text77: "How to bet？",
        text78: "How to bet",
        text79: "FAQ",
        text80: "  Here, you will find the following most common questions and their answers. If you can't find what you're looking for, please feel free to ask in our official discord or telegram group.",
        text81: "Pool Introduce",
        text82: "Distribution：5000 SDR COINS",
        text83: "Running time：1000天（约）",
        text84: "Start time ：2023年1月1日上午11:00（UTC）",
        text85: "Reward for each block：5枚 SDR COINS",
        text86: "How to bet？",
        text87: "Add liquidity to your SDR and USDT through Pancakeswap, then open the farm and save it to your LP (SDR-USDT). You can view your rewards in real time after your deposit.",
        // text88: "如何获取Staked DEX（sDEX）？",
        // text89: "步骤1：连接钱包。",
        // text90: "步骤2：写入金额。",
        // text91: "步骤3：单击“将PINK转换为sPINK”以获取sPINK。如果您想取回PINK，请“用sPINK兑换PINK”。",
        // text92: "步骤4：确认tx。",
        text93: "Calculator",
        text94: "Staking Pool Statistics and Calculator",
        text95: "Pool Stats ",
        text96: "Estimated ",
        text97: "Staking Amount",
        text98: "LP",
        text99: "CALCULATE EARNINGS",
        text100: "Total Stake",
        text101: "Pool Balance",
        text102: "wDEX",
        text103: "Final Pool Block",
        text104: "Block",
        text105: "Pool Expires in",
        text106: "Estimated APR",
        text107: "Hour",
        text108: "Day",
        text109: "Week",
        text110: "Month",
        text111: "Year",
        text112: "Language",
        text113: "waitting",
        text114: "Copied",
        text115: "Pledge",
        text116: "Insufficient funds",
        text117: "Please enter Amount",
        text118: "Cancel",
        text119: "Comfirm",
        text200: "Available balance",
        text201: "Withdraw",
        text202: "Clearing of farming rewards after extraction",
        text203: "Available",
        text204: "Please enter Amount",
        text205: "LP Staked",
        text206: "please enter an Integer",
        text207: "Activity Ended",
        text208: "Minute",
        text209: "Second",
        text210: "Distance start:",
        text211: "Switch",
        text212: "Collect LP in pledge",
        text213: "Direct Push List",
        text214: "No direct push",
        text215: "Empty",
        text216: "Erect",
        text217: "See",
        text218: "cultivation",
        text219: "harvest",
        text220: "Income receivable",
        text221: "Cultivated quantity",
        text222: "Remaining rewards",
        text223: "Farming income",
        text224: "Cultivate USDT and earn SDR",
        text225: "Please enter the farming quantity",
        text226: "Please enter the address of the inviter",
        text227: "privilege grant failed",
        text228: "Successful cultivation",
        text229: "No income withdrawal temporarily",
        text230: "Extraction succeeded",
        text231: "Extraction failed",
        text232: "Recruit Number",
        text233: "Recruit Reward",
        text234: "Receive",
        text235: "Received successfully",
        text236: "Failed to claim",
        /*老翻译*/
    }
}