import publicHead from "@/components/headBack/head.vue";
import menuView from "@/components/menu/menu.vue";
import contractText from "@/assets/script/contract";
import startview from "@/components/startTeam/startTem.vue";
export default {
    data() {
        return {
            // 试图切换索引 
            startView: false,
            // 是否显示菜单
            showMenu: false,
            // 顶部区域数量
            area1: 0,
            area2: 0,
            // 当前用户区域和等级
            currArea: '-',
            currLevel: '-',
            // 当前星星数量
            startNum: 0,
            // 可提金额
            isMoney: 0,
            // 区域能量
            areaMoney: 0,
            //已提金额
            notMoney: 0,
            // 升级能量
            levelMoney: 0,
            // 用户邀请码
            intCode: "-",
            // 用户邀请人数
            findNum: "0",
            // 启动状态
            startStatus: false,
            // 是否显示启动填写邀请码
            isShowIntCodeFloat: false,
            // 启动需要的邀请码
            startIntCode: "",
            // 邀请链接
            linkUrl: "https://starspaces.org/#/stars",
        }
    },
    mounted() {
        this.getAreaNum();
        this.getInitveCode();
        if(this.$store.state.addres) {
            this.getUserStart();
            this.getCurrAccountIntcode();
            this.getCurrAccountFind();
        }
    },
    methods: {

        // 连接钱包执行函数
        connectWallet() {
            var _this = this;
            this.authorization().then(()=>{
                _this.getUserStart();
                _this.getCurrAccountIntcode();
                _this.getCurrAccountFind();
            })
        },

        // 获取区域
        getAreaNum() {
            var contractObj = this.getContract("StarsChain");
            contractObj.methods.rewardThree().call().then((res)=>{
                this.area2 = res;
            });
            contractObj.methods.rewardTwo().call().then((res)=>{
                this.area1 = res;
            });
        },

        // 查询当前账户是否启动
        getUserStart() {
            var _this = this;
            var contractObj = this.getContract("StarsChain");
            contractObj.methods.getUser(this.$store.state.addres).call().then((res)=>{
                console.log(res);
                _this.startStatus = res[0] > 999;
                _this.currArea = res[3].domain;
                _this.currLevel = res[3].level;
                _this.startNum = res[3].count;
                _this.isMoney = _this.accuracyDiv(res[2].energyActive);
                _this.areaMoney = _this.accuracyDiv(res[2].energyDomain);
                _this.notMoney = _this.accuracyDiv(res[2].rewardDebt);
                _this.levelMoney = _this.accuracyDiv(res[2].energyUpgrade);
            });
        },

        // 获取当前账号邀请码
        getCurrAccountIntcode() {
            var _this = this;
            var contractObj = this.getContract("StarsUser");
            contractObj.methods.addressToId(this.$store.state.addres).call().then((res)=>{
                _this.intCode = res;
            });
        },

         // 获取当前账号招募人数
         getCurrAccountFind() {
            var _this = this;
            var contractObj = this.getContract("StarsUser");
            contractObj.methods.shareTotal(this.$store.state.addres).call().then((res)=>{
                _this.findNum = res;
            });
        },

        // 启动按钮执行函数
        startButAction() {
            var _this = this;
            if(!this.$store.state.addres) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: _this.$t("m.text50"),
                }).show();
                return;
            }
            this.isShowIntCodeFloat = true;
        },

        // 关闭邀请码界面
        closeStartView() {
            this.startIntCode = "";
            this.isShowIntCodeFloat = false;
        },

        // 通过邀请码查询邀请人地址
        getIntManAddres() {
            var _this = this;
            if(!this.startIntCode) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',    
                    txt: _this.$t('m.text64'),
                }).show();
                return;
            }
            var loading = this.$createToast({
                time: 0,
                mask: true,
                type: 'loading',
                txt: _this.$t('m.text66')
            }).show();
            var contractObj1 = this.getContract("StarsUser");
            var contractObj2 = this.getContract("StarsChain");
            var contractObj3 = this.getContract("USDT");
            contractObj1.methods.idToAddress(this.startIntCode).call().then((res)=>{
                var intAddres = res;
                contractObj2.methods.getUser(res).call().then((response)=>{
                    let num = response[3].domain;
                    let moeny = 0;
                    if(num == 1) {
                        moeny = 100;
                    } else if(num == 2) {
                        moeny = 1000;
                    }else if(num == 3) {
                        moeny = 10000;
                    }
                    var approve = contractObj3.methods.approve(contractText['StarsChain'].addres, this.accuracyMul(moeny));
                    approve.send({
                        from: _this.$store.state.addres
                    }).then((res)=>{
                        if(res.status) {
                            var launch = contractObj2.methods.launch(intAddres);
                            launch.send({
                                from: _this.$store.state.addres
                            }).then((res)=>{
                                loading.hide();
                              if(res.status) {
                                this.$createToast({
                                    time: 1500,
                                    mask: true,
                                    type: 'correct',
                                    txt: _this.$t('m.text67'),
                                }).show();
                                _this.startStatus = true;
                                _this.getUserStart();
                                _this.getCurrAccountIntcode();
                                _this.getCurrAccountFind();
                                _this.closeStartView();
                              }
                            }).catch((error)=>{
                                loading.hide();
                                this.$createToast({
                                    time: 1500,
                                    mask: true,
                                    type: 'error',
                                    txt: _this.$t('m.text68'),
                                }).show();
                            });
                        }
                    }).catch((res)=>{
                        loading.hide();
                        this.$createToast({
                            time: 1500,
                            mask: true,
                            type: 'error',
                            txt: _this.$t('m.text68'),
                        }).show();
                    });
                });
            });
        },

        // 添加燃料
        addRefueling() {
            if(!this.$store.state.addres) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: this.$t("m.text50"),
                }).show();
                return;
            }
            if(this.currArea == 0) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: this.$t("m.text69"),
                }).show();
                return;
            }
            var loading = this.$createToast({
                time: 0,
                mask: true,
                type: "loading",
                txt: "loding"
            }).show();
            var _this = this;
            var contractObj1 = this.getContract("USDT");
            var contractObj2 = this.getContract("StarsChain");
            var moeny = 0;
            if(this.currArea == 1) {
                moeny = 100;
            }else if(this.currArea == 2) {
                moeny = 1000;
            }else if(this.currArea == 3) {
                moeny = 10000;
            }
            var approve = contractObj1.methods.approve(contractText['StarsChain'].addres, this.accuracyMul(moeny));
            approve.send({
                from: _this.$store.state.addres
            }).then((res)=>{
                if(res.status) {
                    var replenish = contractObj2.methods.replenish();
                    replenish.send({
                        from: _this.$store.state.addres
                    }).then((res)=>{
                        loading.hide();
                        _this.$createToast({
                            time: 1500,
                            mask: true,
                            type: 'correct',
                            txt: _this.$t('m.text67'),
                        }).show
                        _this.getUserStart();
                    }).catch((error)=>{
                        loading.hide();
                        this.$createToast({
                            time: 1500,
                            mask: true,
                            type: 'error',
                            txt: _this.$t('m.text68'),
                        }).show();
                    });
                }
            }).catch((res)=>{
                loading.hide();
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'error',
                    txt: _this.$t('m.text68'),
                }).show();
            });
        },

        // 提取金额
        withdrawMoney() {
            if(!this.$store.state.addres) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: this.$t("m.text50"),
                }).show();
                return;
            }
            if(this.currArea == 0) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: this.$t("m.text69"),
                }).show();
                return;
            }
            if(this.isMoney == 0) {
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'txt',
                    txt: this.$t("m.text70"),
                }).show();
                return;
            }
            var loading = this.$createToast({
                time: 0,
                mask: true,
                type: "loading",
                txt: "Loding"
            }).show();
            var _this = this;
            var contractObj2 = this.getContract("StarsChain");

            var withdrawObj = contractObj2.methods.withdraw();
            withdrawObj.send({
                from: _this.$store.state.addres
            }).then((res)=>{
                loading.hide();
                _this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'correct',
                    txt: _this.$t('m.text67'),
                }).show
                _this.getUserStart();
            }).catch((error)=>{
                loading.hide();
                this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'error',
                    txt: _this.$t('m.text68'),
                }).show();
            });


        },

        // 断开钱包
        offWallet() {
            this.$store.state.addres = '';
        },

        // 复制邀请码
        copyAction(text) {
            var _this = this;
            this.$copyText(text).then(()=>{
                _this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'correct',
                    txt: _this.$t('m.text114'),
                }).show();
            }).catch(()=>{
                _this.$createToast({
                    time: 1500,
                    mask: true,
                    type: 'correct',
                    txt: _this.$t('m.text68'),
                }).show();
            });
        },

        // 获取邀请链接
        getInitveCode() {
            var url = window.location.href;
            var start=url.lastIndexOf("?");
            if(start >0) {
                var params=url.substring(
                    start+1,url.length
                ).split("&");

                var map= {};
                for (let i = 0; i < params.length; i++) {
                    var _center=params[i].indexOf("=");
                    map[params[i].substring(0,_center)]=params[i].substring(_center+1,params[i].length);
                }
                this.startIntCode = map["code"];
                console.log(this.startIntCode);
            }
        },

        // 复制链接
        copyInitveLink() {
            var url = this.linkUrl + "?code=" + this.intCode;
            this.copyAction(url);
        },

        /*
			@获取星星动画的随机秒数
			@参数：无
			@返回值：float
		*/
		getRandAnim:function(){
			return Math.random();
		},
        
        /*
        @随机获取0-100之间的整数
        @参数：min[number] max[number]
        @返回值：float
        */
        getRundNum:function(min,max){
            return Math.floor(Math.random() * (max - min)) + min;
        },

        // 前往link
        goToLinek() {
            window.open("https://www.pinksale.finance/pinklock/liquidity?chain=BSC");
        }
    },

    components: {
        publicHead,
        menuView,
        startview
    }
}