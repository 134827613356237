import menuView from "@/components/menu/menu.vue";
export default {
    data() {
        return {
            // 是否显示菜单
            showMenu: false,
            projectList: [
                {
                    icon: require("@/assets/image/homeproject1.png"),
                    title: this.$t("m.text5"),
                    small: this.$t("m.text18")
                },
                {
                    icon: require("@/assets/image/homeproject2.png"),
                    title: this.$t("m.text19"),
                    small: this.$t("m.text20")
                },
                {
                    icon: require("@/assets/image/homeproject3.png"),
                    title: this.$t("m.text21"),
                    small: this.$t("m.text22")
                },
                {
                    icon: require("@/assets/image/homeproject4.png"),
                    title: this.$t("m.text23"),
                    small: this.$t("m.text24")
                }
            ],
            tokenomiceList: [
                {key: this.$t("m.text25"),value: this.$t("m.text2")},
                {key: "Token",value: "SDR"},
                {key: this.$t("m.text26"),value: "10,000.00"},
                {key: this.$t("m.text27"),value: "BSC"},
            ],
            propList: [
                {name: this.$t("m.text19"), num: 50, color: "#00E2E8"},
                {name: this.$t("m.text28"), num: 36, color: "#2873E9"},
                {name: this.$t("m.text29"), num: 10, color: "#9155F7"},
                {name: this.$t("m.text30"), num: 4, color: "#FF9802"},
            ],
            phaseList: [
                {
                    title: this.$t("m.text31"), 
                    label: [this.$t("m.text32"),this.$t("m.text33"),this.$t("m.text34")],
                    isTit: true,
                    isBor: true
                },
                {
                    title: this.$t("m.text35"), 
                    label: [this.$t("m.text36"),this.$t("m.text37"),this.$t("m.text38")],
                    isTit: true,
                    isBor: false
                },
                {
                    title: this.$t("m.text39"), 
                    label: [this.$t("m.text40"),this.$t("m.text23"),this.$t("m.text41")],
                    isTit: false,
                    isBor: false
                },
                {
                    title: this.$t("m.text42"), 
                    label: [this.$t("m.text43")],
                    isTit: false,
                    isBor: false
                }
            ],
            ecosystemList: [
                {url: require("@/assets/image/ecsoyIcon1.png"),link: ""},
                {url: require("@/assets/image/pinksale.png"),link: ""},
                {url: require("@/assets/image/ecsoyIcon4.png"),link: ""},
            ],
            flootList: [
                {url: require("@/assets/image/flootIcon1.png"),link: ""},
                {url: require("@/assets/image/flootIcon2.png"),link: ""},
                {url: require("@/assets/image/flootIcon3.png"),link: ""},
                {url: require("@/assets/image/flootIcon4.png"),link: ""},
            ]
        }
    },

    async mounted() {
        await this.authorization();
    },

    methods: {
        // 跳转外部链接
        goToUrl(url) {
            window.open(url);
        },

        // 前往内部页面
        goToView(url) {
            this.$router.push({
                path: url
            });
        },

        // 监听切换语言
        changeLan() {
            this.phaseList = [
                {
                    title: this.$t("m.text31"), 
                    label: [this.$t("m.text32"),this.$t("m.text33"),this.$t("m.text34")],
                    isTit: true,
                    isBor: true
                },
                {
                    title: this.$t("m.text35"), 
                    label: [this.$t("m.text36"),this.$t("m.text37"),this.$t("m.text38")],
                    isTit: true,
                    isBor: false
                },
                {
                    title: this.$t("m.text39"), 
                    label: [this.$t("m.text40"),this.$t("m.text23"),this.$t("m.text41")],
                    isTit: false,
                    isBor: false
                },
                {
                    title: this.$t("m.text42"), 
                    label: [this.$t("m.text43")],
                    isTit: false,
                    isBor: false
                }
            ];
            this.projectList = [
                {
                    icon: require("@/assets/image/homeproject1.png"),
                    title: this.$t("m.text5"),
                    small: this.$t("m.text18")
                },
                {
                    icon: require("@/assets/image/homeproject2.png"),
                    title: this.$t("m.text19"),
                    small: this.$t("m.text20")
                },
                {
                    icon: require("@/assets/image/homeproject3.png"),
                    title: this.$t("m.text21"),
                    small: this.$t("m.text22")
                },
                {
                    icon: require("@/assets/image/homeproject4.png"),
                    title: this.$t("m.text23"),
                    small: this.$t("m.text24")
                }
            ];
            this.tokenomiceList = [
                {key: this.$t("m.text25"),value: this.$t("m.text2")},
                {key: "Token",value: "SDR"},
                {key: this.$t("m.text26"),value: "10,000.00"},
                {key: this.$t("m.text27"),value: "BSC"},
            ];
            this.propList = [
                {name: this.$t("m.text19"), num: 50, color: "#00E2E8"},
                {name: this.$t("m.text28"), num: 36, color: "#2873E9"},
                {name: this.$t("m.text29"), num: 10, color: "#9155F7"},
                {name: this.$t("m.text30"), num: 4, color: "#FF9802"},
            ];
        }
    },

    components: {
        menuView
    }
}