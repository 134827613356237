import publicHead from "@/components/headBack/head.vue"
import contractText from "@/assets/script/contract";
const Web3 = require('web3');
export default{
    data() {
        return {
            // 总质押
            totalPledge: 0,
            //pool余额
            poolBalance: 0,
            // 最终区块
            endBlock: 0,
            // 以太坊区块
            ethereBlock: 0,
            // 兑换比例
            ratio: 0,
            // 区块产量
            blockProd: 0,
            // 输入值
            inputNum: "",
        }
    },
    mounted() {
        this.getTotalMsg();
        // 获取以太坊网络中的区块
        var webThreeObj = new Web3(window.ethereum);
        webThreeObj.eth.getBlockNumber().then((res)=>{
            this.ethereBlock = res;
        })
    },

    methods: {
        // 获取统计信息
        getTotalMsg() {
            var contract1 = this.getContract("LpPair");
            var contract2 = this.getContract("SDR");
            var contract3 = this.getContract("FarmingPool");
            var _this = this;
            contract3.methods.rewardPerBlock().call().then((res)=>{
                _this.blockProd = _this.accuracyDiv(res);
            });
            contract1.methods.getReserves().call().then((res)=>{
                var reserve0 = res.reserve0;
                var reserve1 = res.reserve1;
                contract1.methods.token0().call().then((response)=>{
                    if(response == contractText['SDR'].addres) {
                        _this.ratio = _this.decimalAction(reserve1 / reserve0,4);
                    }else{
                        _this.ratio = _this.decimalAction(reserve0 / reserve1,4);
                    }
                });
            });
            contract1.methods.balanceOf(contractText['FarmingPool'].addres).call().then((res)=>{  // LP总质押
                _this.totalPledge = _this.accuracyDiv(res);
            });
            contract2.methods.balanceOf(contractText['FarmingPool'].addres).call().then((res)=>{
                _this.poolBalance = _this.accuracyDiv(res);
            });
            contract3.methods.endBlock().call().then((res)=>{
                _this.endBlock  = res;
            });
        },

        // 计算产量
        countYield(time) {
            if(!this.inputNum){
                return "0.00";
            }
            if(this.inputNum.indexOf(".") != -1) {
                this.$createToast({
                    txt: this.$t("m.text206"),
                    time: 1500,
                    mask: true,
                    type: 'warn',
                }).show();
                this.inputNum = "";
                return "0.00";
            }
            var inputNum = parseInt(this.inputNum);
            var totalNum = parseFloat(this.totalPledge);
            var prodNum = parseFloat(this.blockProd);
            var timeNum = parseInt(time);
            return inputNum / (totalNum + inputNum) * prodNum * timeNum / 3;
        }
    },

    components: {
        publicHead
    }
}