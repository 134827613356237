export default {
    m: {
        text1: "연결",
        text2: "우주 공간",
        text3: "2100 년 지구는 환경과 기후 변화로 인해 생존하기 어려워졌으며 우주선을 다루기 위해 더 많은 우주 비행사를 모집해야합니다.매번 모집을 마칠 때마다 우주선의 등급은 한차례 크게 제고되며 우주비행사들의 상호배합은 우주선이 더욱 많은 에네르기를 획득하여 광활한 공간에서 성간이민을 완성할수 있다.우리는 이번 행동을 스타링크 행동이라고 대신 부른다.",
        text4: "Pancakeswap",
        text5: "스타링크 작전",
        text6: "감사 보고서",
        text7: "백서",
        text8: "프로젝트 소개",
        text9: "스타스페이스는 GameFi 게임으로 첫 번째 단계는 기초 생태 발전과 더 많은 토큰 발굴에 주력할 것이다.당대 화폐가 어느 정도 발굴되고 시장이 충분한 유동성을 갖추면 우리는 공동으로 정식으로 새로운 단계에 진입할 것이다.",
        text10: "토큰 경제학",
        text11: "할당량",
        text12: "BSC의 Token 계약 주소",
        text13: "농장으로 이동",
        text14: "제품 계획",
        text15: "할 일 목록과 이정표는 언제든지 변경할 수 있습니다.",
        text16: "에코시스템 파트너",
        text17: "관심 가져",
        text18: "새로운 우주 비행사를 모집하여 당신의 우주선을 업그레이드하여 당신을 더 높은 우주 공간으로 밀어 넣는 것을 주목합니다.",
        text19: "농장",
        text20: "LP (SDR-USDT) 를 농장에 질권하여 더 많은 경작 보상을 받습니다.",
        text21: "명예의 전당",
        text22: "스타즈는 현재 공간 위치에 따라 해당 등급의 NFT(명예훈장)를 받는다.",
        text23: "GameFi",
        text24: "Stars는 게임을 통해 더 많은 자원을 얻고 더 많은 Token (GSDR) 을 벌었습니다.",
        text25: "프로젝트 이름:",
        text26: "발행 부수:",
        text27: "블록체인 네트워크",
        text28: "예매",
        text29: "스타 체인 진급 보상",
        text30: "실험실",
        text31: "1단계",
        text32: "Token 예약 판매 & DEX",
        text33: "유동성 잠금",
        text34: "계약 감사 보고서",
        text35: "2단계",
        text36: "스타링크 시작",
        text37: "오픈 Token 구매",
        text38: "농장 가동",
        text39: "3단계",
        text40: "CMC & CG & CEX",
        text41: "Web3.0",
        text42: "4단계",
        text43: "새로운 트랙",
        text44: "Stars of Level",
        text45: "정원",
        text46: "제 2 공간 보상",
        text47: "제3공간 보상",
        text48: "우주선 등급",
        text49: "현재 공간",
        text50: "비어 있음",
        text51: "분리",
        text52: "시 작",
        text53: "시작됨",
        text54: "내 창고",
        text55: "에너지 업그레이드",
        text56: "공간 에너지",
        text57: "연료를 첨가하다",
        text58: "이미 인출한 금액",
        text59: "인출 가능한 금액",
        text60: "인출 금액",
        text61: "모집치",
        text62: "초대 코드",
        text63: "신인을 모집하다",
        text64: "초대 코드 필요",
        text65: "초대 코드 입력",
        text66: "오픈 중",
        text67: "성공",
        text68: "실패",
        text69: "먼저 시작하십시오.",
        text70: "인출 금액 없음",
        text71: "SDR-USDT를 질권하여 SDR 보너스 획득",
        text72: "질권의 SDR-USDT",
        text73: "Authorize",
        text74: "되찾다",
        text75: "수익 SDR",
        text76: "추출",
        text77: "베팅은 어떻게 합니까?",
        text78: "베팅 방법",
        text79: "FAQ 응답",
        text80: "  여기서 당신은 아래에서 가장 흔히 볼 수 있는 문제와 그 답안을 발견할 수 있을 것이다.찾는 것을 찾을 수 없으면 언제든지 공식 discord 또는 telegram 그룹에서 문의하십시오.",
        text81: "Pool 소개",
        text82: "할당: 5000 SDR COINS",
        text83: "작동 시간: 약 1000일",
        text84: "시작: 2023년 1월 1일 오전 11시(UTC)",
        text85: "블록당 보상: SDR COINS 5개",
        text86: "Pool에 베팅하려면 어떻게 해야 합니까?",
        text87: "Pancakeswap에서 SDR 및 USDT에 유동성을 추가하고 농장을 열고 LP(SDR-USDT)에 저장합니다.당신은 예금 후 실시간으로 당신의 보상을 볼 수 있습니다.",
        text88: "Staked DEX(sDEX)를 구하려면 어떻게 해야 합니까?",
        text89: "1단계: 지갑을 연결합니다.",
        text90: "2단계: 금액을 기록합니다.",
        text91: "3단계: PINK를 sPINK으로 변환을 클릭하여 sPINK를 가져옵니다.PINK를 되찾으려면 ‘PINK를 sPINK로 바꾸십시오.’",
        text92: "4단계: tx를 확인합니다.",
        text93: "이익 계산기",
        text94: "Staking Pool 통계 및 이익 계산기",
        text95: "Pool 통계",
        text96: "예상 이익",
        text97: "저당 금액:",
        text98: " LP",
        text99: "이익 계산",
        text100: "총질권",
        text101: "Pool 잔액",
        text102: "wDEX",
        text103: "최종 청크",
        text104: "청크",
        text105: "남은 블록",
        text106: "예상 APR",
        text107: "시간",
        text108: "일",
        text109: "요일",
        text110: "월",
        text111: "년",
        text112: "언어",
        text113: "아직 개방하지 않음",
        text114: "복제 성공",
        text115: "질권",
        text116: "잔액 부족",
        text117: "질권 수량을 입력하십시오.",
        text118: "취소",
        text119: "확인",
        text200: "사용 가능한 잔액",
        text201: "질권 추출",
        text202: "추출 후 재배 보상 제로",
        text203: "현재 언급 가능",
        text204: "추출 수량을 입력하십시오.",
        text205: "총 LP 질권",
        text206: "정수를 입력하십시오.",
        text207: "종료",
        text208: "분",
        text209: "초",
        text210: "거리 시작:",
        text211: "시야각 전환",
        text212: "질권 중인 LP 수령",
        text213: "직추 목록",
        text214: "직추 없음",
        text215: "비어 있음",
        text216: "직선",
        text217: "만나다",
        text218: "경작",
        text219: "수확",
        text220: "수취 가능 이익",
        text221: "경작량",
        text222: "남은 보상",
        text223: "경작 수익",
        text224: "USDT 재배, SDR 확보",
        text225: "재배 수량을 입력하십시오.",
        text226: "초대장 주소를 입력하십시오.",
        text227: "인증 실패",
        text228: "재배에 성공하다",
        text229: "이익 인출 없음",
        text230: "추출 성공",
        text231: "추출 실패",
        text232: "모집치",
        text233: "모집 장려",
        text234: "수령",
        text235: "수령 성공",
        text236: "수령 실패",
        /*老翻译*/
    }
}