import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入复制功能
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

// 引入公共样式
import "@/assets/style/public.scss";

// 引入公告方法
import publicScrip from "@/assets/script/public.js";
Vue.use(publicScrip);

// 注册vuex
Vue.prototype.$store = store;

// 引入多语言
import VueI18n from 'vue-i18n';

// 引入语言包
import cn from "@/assets/lang/cn.js"; // 简体中文
import en from "@/assets/lang/en.js"; // 英文
import ft from "@/assets/lang/ft.js"; // 繁体中文
import jp from "@/assets/lang/jp.js"; // 日本语
import hg from "@/assets/lang/hg.js"; // 韩语

// 注册多语言到Vue
Vue.use(VueI18n);

// 获取当前语言包
const langValue = localStorage.getItem("language")

// 创建多语言实例
const i18n = new VueI18n({
	locale: langValue?langValue : 'en',  // 默认为中文
	messages: {
		'cn':cn,
    'en': en,
    'ft': ft,
    'jp': jp,
    'hg': hg
	}
});

// 将多语言包挂载到原型链上
Vue.prototype._i18n = i18n;


Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
