export default {
    m: {
        text1: "連接",
        text2: "星際空間",
        text3: "2100年,地球因環境和氣候變化變得難以生存,您需要招募更多的宇航員來操作宇宙飛船。每完成一次招募,飛船的等級將得到一次巨大提升,宇航們的相互配合可以使飛船獲得更多的能量從而在廣闊的空間中完成星際移民。我們將本次行動代號稱之為-星鏈行動。",
        text4: "Pancakeswap",
        text5: "星鏈行動",
        text6: "審計報告",
        text7: "白皮書",
        text8: "項目簡介",
        text9: "星際空間是一款GameFi遊戲,第一階段將致力於基礎生態發展,和更多的代幣挖掘。當代幣挖掘到一定程度,市場具備足夠的流動性時,我們將共同正式進入全新的階段。",
        text10: "代幣經濟學",
        text11: "配額",
        text12: "BSC上的Token合約地址",
        text13: "前往農場",
        text14: "產品規劃",
        text15: "待辦事項列表和裏程碑，可隨時更改。",
        text16: "生態系統  合作夥伴",
        text17: "關註我們",
        text18: "招募新的宇航員,從而升級您的飛船將您推送到更高級的星際空間。",
        text19: "農場",
        text20: "將LP(SDR-USDT)質押在農場中,從而獲取更多的耕種獎勵。",
        text21: "榮譽殿堂",
        text22: "Stars 根據當前空間位置,領取對應等級的NFT(榮譽勛章)。",
        text23: "GameFi",
        text24: "Stars通過遊戲,獲得更多資源，賺取更多的Token(GSDR)。",
        text25: "項目名稱：",
        text26: "發行量：",
        text27: "區塊鏈網絡",
        text28: "預售",
        text29: "星鏈晉級獎勵",
        text30: "實驗室",
        text31: "第一階段",
        text32: "Token預售 & DEX",
        text33: "流動性鎖定",
        text34: "合約審計報告",
        text35: "第二階段",
        text36: "啟動星鏈",
        text37: "啟動星鏈",
        text38: "啟動農場",
        text39: "第三階段",
        text40: "CMC & CG & CEX",
        text41: "Web3.0",
        text42: "第四階段",
        text43: "全新賽道",
        text44: "Stars of Level",
        text45: "名額",
        text46: "第二空間獎勵",
        text47: "第三空間獎勵",
        text48: "飛船等級",
        text49: "當前空間",
        text50: "未連接錢包",
        text51: "斷開",
        text52: "啟 動",
        text53: "已啟動",
        text54: "我的倉庫",
        text55: "升級能量",
        text56: "空間能量",
        text57: "添加燃料",
        text58: "已提金額",
        text59: "可提金額",
        text60: "提取金額",
        text61: "招募值",
        text62: "邀請碼",
        text63: "招募新人",
        text64: "需要邀請代碼",
        text65: "輸入邀請碼",
        text66: "開啟中",
        text67: "成功",
        text68: "失敗",
        text69: "請先啟動",
        text70: "暫無提取金額",
        text71: "質押您的SDR-USDT,賺取SDR獎勵",
        text72: "質押中的SDR-USDT",
        text73: "Authorize",
        text74: "贖回",
        text75: "賺取的SDR",
        text76: "提取",
        text77: "如何下註？",
        text78: "如何下註",
        text79: "常見問題回答",
        text80: "在這裏，你會發現下面最常見的問題及其答案。如果你找不到你要找的東西，請隨時在我們的官方discord或telegram群中詢問。",
        text81: "Pool 介紹",
        text82: "分配：5000 SDR COINS",
        text83: "運行時間：1000天（約）",
        text84: "開始時間：2023年1月1日上午12:00（UTC）",
        text85: "每個區塊獎勵：5枚 SDR COINS",
        text86: "如何對Pool下註？",
        text87: "將您的SDR 與 USDT 通過Pancakeswap 添加流動性，然後打開農場並存入您的LP(SDR-USDT)。您可以在存款後實時查看您的獎勵。",
        text88: "如何獲取Staked DEX（sDEX）？",
        text89: "步驟1：連接錢包。",
        text90: "步驟2：寫入金額。",
        text91: "步驟3：單擊「將PINK轉換為sPINK」以獲取sPINK。如果您想取回PINK，請「用sPINK兌換PINK」。",
        text92: "步驟4：確認tx。",
        text93: "收益計算器",
        text94: "Staking Pool統計和收益計算器",
        text95: "Pool 統計信息",
        text96: "預計收益",
        text97: "抵押金額：",
        text98: " LP",
        text99: "計算收益",
        text100: "總質押",
        text101: "Pool 余額",
        text102: "SDR",
        text103: "最終區塊",
        text104: "區塊",
        text105: "剩余區塊",
        text106: "預計APR",
        text107: "小時",
        text108: "天",
        text109: "星期",
        text110: "月",
        text111: "年",
        text112: "語言",
        text113: "暫未開放",
        text114: "復製成功",
        text115: "質押",
        text116: "余額不足",
        text117: "請輸入質押數量",
        text118: "取消",
        text119: "確定",
        text200: "可用余額",
        text201: "提取質押",
        text202: "提取後耕種獎勵清零",
        text203: "當前可提",
        text204: "請輸入提取數量",
        text205: "LP質押總數",
        text206: "請輸入整數",
        text207: "已結束",
        text208: "分",
        text209: "秒",
        text210: "距離開始：",
        text211: "切換視角",
        text212: "領取質押中的LP",
        text213: "直推列表",
        text214: "暫無直推",
        text215: "空",
        text216: "直",
        text217: "見",
        text218: "耕種",
        text219: "收獲",
        text220: "可收取收益",
        text221: "耕種數量",
        text222: "剩余獎勵",
        text223: "耕種收益",
        text224: "耕種USDT,賺取SDR",
        text225: "請輸入耕種數量",
        text226: "請輸入邀請人地址",
        text227: "授權失敗",
        text228: "耕種成功",
        text229: "暫無收益提取",
        text230: "提取成功",
        text231: "提取失敗",
        text232: "招募值",
        text233: "招募獎勵",
        text234: "領取",
        text235: "領取成功",
        text236: "領取失敗",
        /*老翻译*/
    }
}